// src/app/components/auth/hooks/useIncompleteUserProcessing.ts

import { useSetAtom } from 'jotai';
import { incompleteUserDataAtom } from 'components/auth/store';
import { useRouter } from 'next/router';
import useAuthComplete from 'components/auth/hooks/useAuthComplete';
import { useUserDataMutation } from 'components/auth/hooks/authFlowHooks';
import { LoginType } from 'auth/constants';
import { useUserDetails } from 'auth/mutations/userHooks';
import { accountCompletedAtom } from 'components/checkout/buoy/jotaiStore';
import boatsetterPaths, { boatsetterFlags } from 'utils/boatsetterPaths';
import { useUser } from '@clerk/nextjs';
import { getUserMetadata } from 'helpers/clerk';
import { useFeatureFlagQueryByName } from 'components/layouts/hooks';

/**
 * Hook to handle scenarios where a user's account might be incomplete after authentication.
 * This hook checks if a user needs to complete their account profile and redirects them
 * to the appropriate "complete account" page if necessary.
 *
 * It supports both the legacy authentication flow and the new Clerk-based authentication flow,
 * determined by the 'clerkSignup' feature flag.
 *
 * **Important Note:** The legacy authentication flow and related code paths are intended to be
 * removed once the Clerk migration is fully completed. Keep an eye out for cleanup tasks
 * to remove the legacy logic when it's no longer needed.
 */
export default function useIncompleteUserProcessing() {
  const router = useRouter();
  const { handleUserAfterAuthActions, runAnalytics } = useAuthComplete();
  const userDataMutation = useUserDataMutation();

  // Exposes the feature flag loading state.
  const { flag: isClerkEnabled, isLoading: isFeatureFlagLoading } = useFeatureFlagQueryByName(
    boatsetterFlags.clerkSignup
  );

  // Legacy user details hook (always called in legacy flow to fetch user data).
  const { userDetails, isLoading: useUserDetailsIsLoading } = useUserDetails();

  // Clerk user hook (always called in clerk flow to access Clerk user object).
  const { user, isLoaded: isClerkLoaded } = useUser();

  const setIncompleteUserData = useSetAtom(incompleteUserDataAtom);
  const setAccountCompleted = useSetAtom(accountCompletedAtom);

  // Consolidate preconditions:
  // - Clerk flow: feature flag has loaded, Clerk is enabled, Clerk data is loaded, and a user exists.
  // - Legacy flow: feature flag has loaded, Clerk is not enabled, user details have loaded, and details exist.
  const areClerkPreconditionsMet = !isFeatureFlagLoading && isClerkEnabled && isClerkLoaded && !!user;
  const areLegacyPreconditionsMet =
    !isFeatureFlagLoading && !isClerkEnabled && !useUserDetailsIsLoading && !!userDetails;
  const isPreconditionsMet = areClerkPreconditionsMet || areLegacyPreconditionsMet;

  // Helper that polls until preconditions are met (or times out after 5 seconds).
  // This is important because we need to ensure feature flags and user data are loaded
  // before we can reliably determine if the user's account is complete.
  const waitForPreconditions = async () => {
    const pollInterval = 50; // ms
    const timeout = 5000; // ms
    let elapsed = 0;

    return new Promise<void>((resolve, reject) => {
      const interval = setInterval(() => {
        if (isPreconditionsMet) {
          clearInterval(interval);
          resolve();
        } else {
          elapsed += pollInterval;
          if (elapsed >= timeout) {
            clearInterval(interval);
            reject(new Error('Timeout waiting for preconditions'));
          }
        }
      }, pollInterval);
    });
  };

  /**
   * Internal function to handle account completion checks when triggered from within the application (e.g., profile page).
   * It checks if the user's account is complete. If not, it redirects them to the "complete account" page.
   * @param redirectTo - The path to redirect to after account completion (if account is already complete).
   * @returns {Promise<boolean>} - Returns true if the account is complete (or becomes complete), false otherwise (redirection occurred).
   */
  const executeIncompleteAccountInApp = async (redirectTo: string) => {
    if (isClerkEnabled) {
      // Clerk-based account completion check.
      const userMetadata = getUserMetadata(user.unsafeMetadata as Record<string, unknown>);
      if (userMetadata.account_completed) {
        setAccountCompleted(true);
        return true;
      }
      // Account is incomplete in Clerk flow:
      // Set incomplete user data in atom and redirect to Clerk's "complete account" page.
      setIncompleteUserData({
        phone: user.primaryPhoneNumber?.phoneNumber || '',
        last_name: user.lastName || '',
        accepted_terms_at: userMetadata.accepted_terms_at || '',
        tcpa_accepted_at: userMetadata.tcpa_accepted_at || '',
        first_name: user.firstName || '',
        account_completed: userMetadata.account_completed || false,
        birthdate: userMetadata.birthdate || '',
        email: user.primaryEmailAddress?.emailAddress || '',
        phone_verified: user.primaryPhoneNumber?.verification?.status === 'verified' || false,
      });
      await router.push({
        pathname: `${boatsetterPaths.auth.completeAccount}`,
        query: { redirect_to: redirectTo },
      });
      setAccountCompleted(false); // Reset accountCompleted atom as we are redirecting for completion
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      // Legacy account completion check.
      if (userDetails?.account_completed) {
        setAccountCompleted(true);
        return true;
      }
      // Account is incomplete in Legacy flow:
      // Set incomplete user data in atom and redirect to legacy "complete account" page.
      setIncompleteUserData({
        phone: userDetails.phone || '',
        last_name: userDetails.last_name || '',
        accepted_terms_at: userDetails.accepted_terms_at || '',
        tcpa_accepted_at: userDetails.tcpa_accepted_at || '',
        first_name: userDetails.first_name || '',
        account_completed: userDetails.account_completed || false,
        birthdate: userDetails.birthdate || '',
        email: userDetails.email || '',
        phone_verified: userDetails.phone_verified || false,
      });
      await router.push({
        pathname: `${boatsetterPaths.signUp}/complete_account`,
        query: { redirect_to: redirectTo },
      });
      setAccountCompleted(false); // Reset accountCompleted atom as we are redirecting for completion
      return false;
    }
  };

  /**
   * Internal function to handle account completion checks immediately after authentication.
   * It checks if the user's account is complete. If not, it redirects them to the "complete account" page.
   * @param {LoginType} loginType - The type of login method used.
   * @param {boolean} isFirstTimeSocial - Indicates if this is the user's first time logging in with a social provider.
   * @returns {Promise<boolean>} - Returns true if the account is complete, false otherwise (redirection occurred).
   */
  const executeIncompleteAccountInAuth = async (loginType: LoginType, isFirstTimeSocial: boolean) => {
    if (isClerkEnabled) {
      // Clerk-based account check after authentication.
      const userMetadata = getUserMetadata(user.unsafeMetadata as Record<string, unknown>);
      runAnalytics(
        {
          id: user.id,
          email: user.primaryEmailAddress?.emailAddress || '',
          created_at: new Date(user.createdAt).toISOString(),
        },
        loginType
      );
      if (userMetadata.account_completed) {
        await handleUserAfterAuthActions(); // Perform post-auth actions if account is complete
        return true;
      }
      // Account is incomplete in Clerk flow:
      // Set incomplete user data in atom and redirect to Clerk's "complete account" page.
      setIncompleteUserData({
        phone: user.primaryPhoneNumber?.phoneNumber || '',
        last_name: user.lastName || '',
        first_name: user.firstName || '',
        account_completed: userMetadata.account_completed || false,
        birthdate: userMetadata.birthdate || '',
        email: user.primaryEmailAddress?.emailAddress || '',
        tcpa_accepted_at: userMetadata.tcpa_accepted_at || '',
        accepted_terms_at: userMetadata.accepted_terms_at || '',
        phone_verified: user.primaryPhoneNumber?.verification?.status === 'verified' || false,
      });
      await router.push({
        pathname: `${boatsetterPaths.signUp}/clerk_complete_account`,
        query: { ...router.query, isFirstTimeSocial },
      });
      return false;
      // eslint-disable-next-line no-else-return
    } else {
      // Legacy flow account check after authentication.
      const userData = await userDataMutation.mutateAsync();
      runAnalytics(userData, loginType);
      setIncompleteUserData({
        phone: userData.phone || '',
        last_name: userData.last_name || '',
        first_name: userData.first_name || '',
        account_completed: userData.account_completed || false,
        birthdate: userData.birthdate || '',
        email: userData.email || '',
        tcpa_accepted_at: userData.tcpa_accepted_at || '',
        accepted_terms_at: userData.accepted_terms_at || '',
        phone_verified: userData.phone_verified || false,
      });
      if (userData.account_completed) {
        await handleUserAfterAuthActions(); // Perform post-auth actions if account is complete
        return true;
      }
      await router.push({
        pathname: `${boatsetterPaths.signUp}/complete_account`,
        query: { ...router.query, isFirstTimeSocial },
      });
      return false;
    }
  };

  /**
   * Public API to handle possible incomplete accounts when triggered from within the application (e.g., messaging an owner).
   * Waits for preconditions to be met and then executes the account completion check.
   * @param {string} redirectTo - The path to redirect to after account completion.
   * @returns {Promise<boolean>} - Returns true if the account is complete, false otherwise (redirection occurred or preconditions not met).
   */
  const handlePossibleIncompleteAccountInApp = async (redirectTo: string) => {
    try {
      await waitForPreconditions(); // Ensure feature flags and user data are loaded
      return executeIncompleteAccountInApp(redirectTo);
    } catch (error) {
      console.error('Preconditions not met for in-app account completion', error);
      return false; // Indicate failure due to preconditions not being met.
    }
  };

  /**
   * Public API to handle possible incomplete accounts immediately after authentication.
   * Waits for preconditions to be met and then executes the account completion check.
   * @param {LoginType} loginType - The type of login method used.
   * @param {boolean} isFirstTimeSocial - Indicates if this is the user's first time logging in with a social provider.
   * @returns {Promise<boolean>} - Returns true if the account is complete, false otherwise (redirection occurred or preconditions not met).
   */
  const handlePossiblyIncompleteAccountInAuth = async (loginType: LoginType, isFirstTimeSocial: boolean) => {
    try {
      await waitForPreconditions(); // Ensure feature flags and user data are loaded
      return executeIncompleteAccountInAuth(loginType, isFirstTimeSocial);
    } catch (error) {
      console.error('Preconditions not met for auth account completion', error);
      return false; // Indicate failure due to preconditions not being met.
    }
  };

  return {
    handlePossiblyIncompleteAccountInAuth,
    handlePossibleIncompleteAccountInApp,
    isReady: isPreconditionsMet, // Indicates if the hook is ready to perform account completion checks (preconditions met)
  };
}
